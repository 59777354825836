import { DateTime } from 'luxon'

export function formatDate(dateTime: DateTime) {
  return dateTime.toLocaleString(DateTime.DATE_SHORT)
}

export function formatMsDate(ts: number) {
  return formatDate(DateTime.fromMillis(ts))
}

export function formatIsoDate(isoDate: string) {
  return formatDate(dateTimeFromISO(isoDate))
}

export function formatTime(dateTime: DateTime) {
  return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function formatIsoTime(isoDate: string) {
  return formatTime(dateTimeFromISO(isoDate))
}

export function formatMsTime(milliseconds: number) {
  return formatTime(DateTime.fromMillis(milliseconds))
}

export function dateTimeFromISO(isoDate: string) {
  // Make sure to preserve the time zone set in the ISO date
  // Otherwise it would be converted to the systems's time zone
  return DateTime.fromISO(isoDate, { setZone: true })
}

export const bucketShiftHour = 12 // date changes at noon

export function noonDate(dateTime: DateTime) {
  const day = dateTime.hour < bucketShiftHour ? 1 : 0
  return dateTime.minus({ day }).toISODate()
}

export const HOLIDAYS = [
  '2024-12-25',

  '2025-01-01',
  '2025-04-21',
  '2025-05-01',
  '2025-05-08',
  '2025-05-29',
  '2025-06-09',
  '2025-07-14',
  '2025-08-15',
  '2025-11-01',
  '2025-11-11',
  '2025-12-25',

  '2026-01-01',
  '2026-04-06',
  '2026-05-01',
  '2026-05-08',
  '2026-05-14',
  '2026-05-25',
  '2026-07-14',
  '2026-08-15',
  '2026-11-01',
  '2026-11-11',
  '2026-12-25',
]

export const isHoliday = (date: DateTime) => {
  return date.isWeekend || HOLIDAYS.includes(date.toISODate())
}
