import {
  LabelKey,
  getLabelsGroupConfigFromTitle,
  labelsPaginationMap,
} from 'common/ontology'
import { addOggExtension, removeOggExtension } from 'common/soundKey'
import { HardLabel, LabelsGroupConfig, OntologyType, Range } from 'common/types'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { OSO_SOUNDS } from 'shared/types/azureSpeech'
import { isObjectEmpty } from 'shared/utils/defined'
import { formatIsoTime } from 'shared/utils/timeFormat'
import { Deferred } from 'shared/utils/web/deferred'
import { PaginationWrapper } from './Pagination'
import { Player } from './Player'
import { RangesEditor } from './RangesEditor'
import { Summary } from './Summary'
import { Button } from './components'
import { DIALOG_CLOSED_REASON } from './components/Dialog'
import { DropdownCheckboxes } from './components/DropdownCheckboxes'
import { HardLabelButton } from './components/HardLabelButton'
import { LabelsGroupButton } from './components/LabelsGroupButton'
import { LabelsProposalsDialog } from './components/LabelsProposalsDialog'
import { SubLabelsDialog } from './components/SubLabelsDialog'
import { TranslationSection } from './components/TranslationSection'
import { app, auth } from './firebase'
import { get } from './firebaseMethods'
import { useSounds } from './hooks/useSounds'

const CONFIRM_MSG =
  "Aucun label n'est sélectionné, êtes-vous sur de vouloir valider votre labellisation ?"

const CONFIRM_MSG_PREVIOUS_SOUNDS =
  "En revenant au son précédent, vous perdrez les labels en cours d'édition. Êtes-vous sûr de vouloir continuer ?"

const LOW_PLAYBACK_RATES = [0.5, 0.75, 1]
const DEFAULT_PLAYBACK_RATES = [...LOW_PLAYBACK_RATES, 1.25, 1.5, 2]

type LabelingConfig = {
  helpUrl: string
  enableIAmSure: boolean
  enableShareUrl: boolean
  noSelectedLabelsAllowed: boolean
  displayLabelTime: boolean
  displayProgress: boolean
  playbackRates: number[]
}

export const labelingConfigMap: Record<OntologyType, LabelingConfig> = {
  layer1: {
    helpUrl:
      'https://www.notion.so/osoai/Page-d-aide-Ontologie-fusionn-e-7aa22f70afec4862ad31ea6989ddf82c',
    enableIAmSure: true,
    enableShareUrl: true,
    noSelectedLabelsAllowed: false,
    displayLabelTime: true,
    displayProgress: false,
    playbackRates: DEFAULT_PLAYBACK_RATES,
  },
  demo: {
    helpUrl:
      'https://www.notion.so/osoai/Page-d-aide-Application-de-test-22085ac6a4fa444d8445e20c591dfbba',
    enableIAmSure: false,
    enableShareUrl: false,
    noSelectedLabelsAllowed: false,
    displayLabelTime: true,
    displayProgress: true,
    playbackRates: DEFAULT_PLAYBACK_RATES,
  },
  respiration: {
    helpUrl:
      'https://www.notion.so/osoai/Pneumologie-APHP-dfb71f2ae17e4fe09915eecd7254d069',
    enableIAmSure: false,
    enableShareUrl: true,
    noSelectedLabelsAllowed: true,
    displayLabelTime: false,
    displayProgress: false,
    playbackRates: DEFAULT_PLAYBACK_RATES,
  },
  keyword: {
    helpUrl:
      'https://www.notion.so/osoai/Keyword-Page-d-aide-a179a7c51379492fb081d6c2647feb97',
    enableIAmSure: true,
    enableShareUrl: true,
    noSelectedLabelsAllowed: true,
    displayLabelTime: true,
    displayProgress: false,
    playbackRates: DEFAULT_PLAYBACK_RATES,
  },
  unet: {
    helpUrl:
      'https://www.notion.so/osoai/UNET-Page-d-aide-18ed92649c0749b19268bb105e99c89f',
    enableIAmSure: false,
    enableShareUrl: true,
    noSelectedLabelsAllowed: true,
    displayLabelTime: true,
    displayProgress: false,
    playbackRates: LOW_PLAYBACK_RATES,
  },
}

type Props = {
  getUrl: (path: string) => Promise<string>
}

export const Labeling = ({ getUrl }: Props) => {
  const {
    helpUrl,
    enableIAmSure,
    enableShareUrl,
    noSelectedLabelsAllowed,
    displayLabelTime,
    displayProgress,
    playbackRates,
  } = labelingConfigMap[import.meta.env.VITE_ONTOLOGY]
  const labelsPagination = labelsPaginationMap[import.meta.env.VITE_ONTOLOGY]

  const [soundUrl, setSoundUrl] = useState<string | undefined>(undefined)
  const [firstSoundHasBeenLabeled, setFirstSoundHasBeenLabeled] =
    useState(false)
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [showSummary, setShowSummary] = useState<boolean>(false)
  const [isPopUpMode, setIsPopUpMode] = useState<boolean>(false)

  const [labels, setLabels] = useState<Partial<Record<LabelKey, LabelKey[]>>>(
    {},
  )
  const [labelsProposals, setLabelsProposals] = useState<string[]>([])
  const [pendingHardLabel, setPendingHardLabel] = useState<LabelKey | null>(
    null,
  )
  const [ranges, setRanges] = useState<Range[]>([])

  const [labelingHistory, setLabelingHistory] = useState<
    Record<
      string,
      {
        labels: typeof labels
        labelsProposals: typeof labelsProposals
        ranges: typeof ranges
      }
    >
  >({})

  const [labelsProposalsDialogData, setLabelsProposalsDialogData] = useState<{
    labelsProposals: string[]
    deferred: Deferred<string[]>
  } | null>(null)

  const [subLabelsDialogData, setSubLabelsDialogData] = useState<{
    labelsGroupConfig: LabelsGroupConfig
    deferred: Deferred<void>
  } | null>(null)

  const [totalLabeledSounds, setTotalLabeledSounds] = useState<
    number | undefined
  >(undefined)
  const [totalSoundsInQueue, setTotalSoundsInQueue] = useState<
    number | undefined
  >(undefined)

  const asyncSound = useSounds()

  const currentParticle =
    window.location.href.split('=')[1] ??
    window.location.pathname.replace(/^\//, '')

  const resetLabeling = useCallback(() => {
    const particle = asyncSound.data?.particle
    if (particle === undefined) return
    setLabels(labelingHistory[particle]?.labels ?? {})
    setLabelsProposals(labelingHistory[particle]?.labelsProposals ?? [])
    setRanges(labelingHistory[particle]?.ranges ?? [])
    setCurrentPageIndex(0)
    setShowSummary(false)
    setPendingHardLabel(null)
  }, [asyncSound.data?.particle, labelingHistory])

  // Reset UI when submitting a new particle to user
  useEffect(() => {
    if (asyncSound.loading) return
    resetLabeling()
  }, [asyncSound.loading, asyncSound.data?.particle, resetLabeling])

  useEffect(() => {
    const particle = asyncSound.data?.particle
    if (particle === undefined) return

    window.history.replaceState(
      null,
      '',
      `${window.location.origin}/${particle}`,
    )
  }, [asyncSound.data?.particle])

  useLayoutEffect(() => {
    function handleWindowResize() {
      setIsPopUpMode(window.innerWidth < 600 || window.innerHeight < 600)
    }
    handleWindowResize()

    window.addEventListener('resize', handleWindowResize)
  })

  useEffect(() => {
    async function fetchUrl() {
      const particle = asyncSound.data?.particle
      if (particle === undefined) {
        setSoundUrl(undefined)
      } else {
        const soundUrl = await getUrl(addOggExtension(particle))
        setSoundUrl(soundUrl)
      }
    }

    fetchUrl()
  }, [asyncSound.data?.particle, getUrl])

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      // Play / Pause
      if (e.key === 'h' || e.key === 'H') {
        const currentPageHardLabels = labelsPagination[currentPageIndex]
          .filter((labelConfig) => labelConfig.type === 'hard')
          .map((labelConfig) => labelConfig.title)

        setPendingHardLabel(
          currentPageHardLabels[
            ((pendingHardLabel
              ? currentPageHardLabels.indexOf(pendingHardLabel)
              : -1) +
              1) %
              currentPageHardLabels.length
          ],
        )
      }
    }

    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  })

  useEffect(() => {
    setPendingHardLabel(null)
  }, [currentPageIndex])

  const user = auth.currentUser

  useEffect(() => {
    async function fetchLabelerStats() {
      if (displayProgress && user !== null) {
        const sounds = await get(`sounds`)
        setTotalLabeledSounds(
          Object.values(sounds).filter((sound) =>
            (sound.labelerUids ?? []).includes(user.uid),
          ).length,
        )
        setTotalSoundsInQueue(Object.keys(sounds).length)
      }
    }
    fetchLabelerStats()
  }, [displayProgress, user])

  const hardLabels = useMemo(() => {
    const hardLabels: HardLabel[] = []
    for (const range of ranges) {
      if (range.label !== undefined)
        hardLabels.push({
          start: range.start,
          end: range.end,
          label: range.label,
        })
    }
    return hardLabels
  }, [ranges])

  const isValidationAllowed = useMemo(
    () =>
      (!isObjectEmpty(labels) ||
        hardLabels.length !== 0 ||
        noSelectedLabelsAllowed) &&
      currentPageIndex === labelsPagination.length - 1,
    [
      labels,
      hardLabels,
      currentPageIndex,
      labelsPagination,
      noSelectedLabelsAllowed,
    ],
  )

  const askConfirmation = useMemo(
    () =>
      noSelectedLabelsAllowed &&
      isObjectEmpty(labels) &&
      hardLabels.length === 0,
    [labels, hardLabels, noSelectedLabelsAllowed],
  )

  if (asyncSound.loading) {
    return <h2>Chargement...</h2>
  }

  if (asyncSound.data === null) {
    return <h2>Aucun son</h2>
  }

  const { saveLabels, prev, next, prevDisabled, nextDisabled } = asyncSound.data

  const labelTime = formatIsoTime(removeOggExtension(asyncSound.data.name))

  function moveToNextPage() {
    setCurrentPageIndex(currentPageIndex + 1)
  }

  function moveToPreviousPage() {
    setCurrentPageIndex(currentPageIndex - 1)
  }

  function moveToFirstPage() {
    setCurrentPageIndex(0)
  }

  function toggleLabelsGroup(label: LabelKey) {
    setLabels((labels) => {
      const newLabels = { ...labels }

      if (newLabels[label] !== undefined) {
        // If group already exists
        delete newLabels[label]
      } else {
        // If group does not exist yet
        newLabels[label] = []
      }
      return newLabels
    })
  }

  function toggleSubLabel(label: LabelKey, subLabel: LabelKey) {
    setLabels((labels) => {
      const newLabels = { ...labels }

      const group = newLabels[label]

      if (group === undefined) {
        newLabels[label] = [subLabel]
      } else {
        if (group.includes(subLabel)) {
          group.splice(group.indexOf(subLabel), 1)
          if (group.length === 0) {
            delete newLabels[label]
          } else {
            newLabels[label] = group
          }
        } else {
          newLabels[label] = [...group, subLabel]
        }
      }

      return newLabels
    })
  }

  function togglePendingHardLabel(label: LabelKey) {
    if (pendingHardLabel !== label) {
      setPendingHardLabel(label)
    } else {
      setPendingHardLabel(null)
    }
  }

  function sendLabels(iAmSure: boolean) {
    if (asyncSound.data === null) return
    saveLabels(labels, labelsProposals, hardLabels, iAmSure)
    if (!firstSoundHasBeenLabeled) {
      setFirstSoundHasBeenLabeled(true)
    }
    const particle = asyncSound.data.particle
    setLabelingHistory((labelingHistory) => ({
      ...labelingHistory,
      [particle]: { labels, labelsProposals, ranges },
    }))
  }

  async function openLabelsProposalsDialog() {
    const deferred = new Deferred<string[]>()
    setLabelsProposalsDialogData({ labelsProposals, deferred })

    try {
      setLabelsProposals(await deferred.promise)
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setLabelsProposalsDialogData(null)
    }
  }

  async function openSubLabelsDialog(labelsGroupTitle: LabelKey) {
    if (isPopUpMode) {
      const labelsGroupConfig = getLabelsGroupConfigFromTitle(
        labelsPagination,
        labelsGroupTitle,
      )
      const deferred = new Deferred<void>()
      setSubLabelsDialogData({ labelsGroupConfig, deferred })

      try {
        await deferred.promise
      } catch (error) {
        if (error !== DIALOG_CLOSED_REASON) {
          throw error
        }
      } finally {
        setSubLabelsDialogData(null)
      }
    }
  }

  async function copySharedLinkToClipboard() {
    const particle = asyncSound.data?.particle
    if (particle === undefined) return
    const link = `${window.location.origin}/?listen=${addOggExtension(particle)}`
    await navigator.clipboard.writeText(link)
    alert(
      `Le lien de partage a été copié dans le presse papier (Ctrl + V pour l'utiliser).`,
    )
  }

  function switchLabels() {
    setRanges((prevRanges) =>
      prevRanges.map((range) => {
        if (range.label === 'expiration') range.label = 'inspiration'
        else if (range.label === 'inspiration') range.label = 'expiration'
        return range
      }),
    )
  }

  return (
    <div className="flex min-h-screen w-screen flex-col">
      {soundUrl && (
        <Player
          labelTime={displayLabelTime ? labelTime : null}
          src={soundUrl}
          autoplay={firstSoundHasBeenLabeled}
          mutedRanges={ranges.filter((range) => range.label === undefined)}
          playbackRates={playbackRates}
        >
          {(audioElementRef, audioDuration) => (
            <RangesEditor
              audioDuration={audioDuration}
              audioElementRef={audioElementRef}
              ranges={ranges}
              onRangeChanges={setRanges}
              labelsPagination={labelsPagination}
              pendingHardLabel={pendingHardLabel}
            />
          )}
        </Player>
      )}
      {displayProgress && (
        <div className="p-2 text-2xl">
          {`${
            totalLabeledSounds ?? 0
          } labellisations réalisées / ${totalSoundsInQueue} sons disponibles dans l'application`}
        </div>
      )}
      <TranslationSection
        particleRef={currentParticle}
        bucket={OSO_SOUNDS}
        Button={Button}
        DropdownCheckboxes={DropdownCheckboxes}
        app={app}
      />

      {!showSummary ? (
        <PaginationWrapper
          currentPageIndex={currentPageIndex}
          totalPages={labelsPagination.length}
          onNextPageClick={moveToNextPage}
          onFirstPageClick={moveToFirstPage}
          onPreviousPageClick={moveToPreviousPage}
        >
          {labelsPagination[currentPageIndex].map((config) =>
            config.type === 'soft' ? (
              <LabelsGroupButton
                key={config.title}
                activatedLabels={labels}
                labelsGroupConfig={config}
                onLabelsGroupClick={toggleLabelsGroup}
                onSubLabelClick={toggleSubLabel}
                popUpHandler={openSubLabelsDialog}
              />
            ) : (
              <HardLabelButton
                key={config.title}
                pendingHardLabel={pendingHardLabel}
                onClick={togglePendingHardLabel}
                hardLabelConfig={config}
              />
            ),
          )}
        </PaginationWrapper>
      ) : (
        <Summary
          activatedLabels={labels}
          labelsPagination={labelsPagination}
        ></Summary>
      )}
      {labelsProposalsDialogData && (
        <LabelsProposalsDialog
          labelsProposalsDialogData={labelsProposalsDialogData}
        />
      )}
      {subLabelsDialogData && (
        <SubLabelsDialog
          activatedLabels={labels}
          subLabelsDialogData={subLabelsDialogData}
          onClose={toggleLabelsGroup}
          onSubLabelClick={toggleSubLabel}
        />
      )}
      <div className="flex flex-row flex-wrap gap-4 p-4">
        <Button title="Ouvrir la page d'aide">
          <a
            style={{ textDecoration: 'none', color: 'white' }}
            target="_blank"
            rel="noopener noreferrer"
            href={helpUrl}
          >
            <b>?</b>
          </a>
        </Button>
        {enableShareUrl && (
          <Button
            title="Partager le son"
            disabled={asyncSound.data.particle === undefined}
            onClick={copySharedLinkToClipboard}
          >
            <span role="img" aria-label="Partager">
              🔗
            </span>
          </Button>
        )}
        <Button
          title="Commentaire facultatif / Suggérer un nouveau label"
          onClick={() => openLabelsProposalsDialog()}
          primary={labelsProposals.length !== 0}
        >
          <span role="img" aria-label="Commenter / Suggérer">
            💬
          </span>
        </Button>
        <Button
          title="Réinitialiser la labellisation"
          className="hover:bg-red-500 hover:bg-opacity-100"
          onClick={() => {
            if (
              window.confirm(
                'Êtes-vous sûr de vouloir réinitialiser la labellisation de ce son ?',
              )
            ) {
              resetLabeling()
            }
          }}
        >
          <span role="img" aria-label="Retour" style={{ fontWeight: 'bold' }}>
            🗑️
          </span>
        </Button>
        <Button
          title="Son précédent"
          disabled={prevDisabled}
          primary
          onClick={() => {
            if (!window.confirm(CONFIRM_MSG_PREVIOUS_SOUNDS)) return
            prev()
          }}
        >
          &larr;
        </Button>
        <Button
          title="Son suivant"
          disabled={nextDisabled}
          primary
          onClick={next}
        >
          &rarr;
        </Button>
        {enableIAmSure && (
          <Button
            disabled={!isValidationAllowed}
            primary
            className="flex-1"
            onClick={() => {
              if (askConfirmation && !window.confirm(CONFIRM_MSG)) return
              sendLabels(true)
            }}
          >
            Je suis sûr
          </Button>
        )}
        <Button
          disabled={!isValidationAllowed}
          primary
          className="flex-1"
          onClick={() => {
            if (askConfirmation && !window.confirm(CONFIRM_MSG)) return
            sendLabels(false)
            setTotalLabeledSounds((totalLabeledSounds ?? 0) + 1)
          }}
        >
          Valider
        </Button>
        {import.meta.env.VITE_ONTOLOGY === 'unet' && (
          <Button
            title="Échanger les labels sélectionnés"
            onClick={() => {
              switchLabels()
            }}
          >
            <span role="img" aria-label="Échanger les labels sélectionnés">
              🔃
            </span>
          </Button>
        )}
        <Button
          title="Afficher les labels sélectionnés"
          onClick={() => {
            setShowSummary(!showSummary)
          }}
          primary={showSummary}
        >
          <span role="img" aria-label="Voir les labels sélectionnés">
            🏷️
          </span>
        </Button>
      </div>
    </div>
  )
}
