import { z } from 'zod'
import { DeviceState } from '../apps/listen'

export type DeviceHistory = DeviceState[]
export type DevicesHistory = Record<string, DeviceHistory>

export const OSO_SOUNDS = 'oso-sounds'
export const OSO_RESP_SOUNDS = 'oso-resp-sounds'
export const OSO_CHILDREN_SOUNDS = 'cp-children-sounds'

export const BUCKET_NAMES = [
  OSO_SOUNDS,
  OSO_RESP_SOUNDS,
  OSO_CHILDREN_SOUNDS,
] as const

export type BucketName = (typeof BUCKET_NAMES)[number]

export const TranslateSpeechSchema = z.object({
  particleRef: z.string(),
  bucket: z.enum(BUCKET_NAMES),
  candidateLanguages: z.array(z.string()).min(1).max(4).default(['fr-FR']),
  awsCredentials: z.object({
    accessKeyId: z.string(),
    secretAccessKey: z.string(),
  }),
  azureCredentials: z.object({
    speechKey: z.string(),
    serviceRegion: z.string(),
  }),
})

export type TranslateSpeechParams = z.infer<typeof TranslateSpeechSchema>

export type TranslateSpeechResponseData = {
  originalText: string
  translatedText: string
  detectedLanguage: string
}

export type LanguageOption = {
  name: string
  code: string
}

export type MilvusCredentials = {
  apiKey: string
  uri: string
}

export type AzureCredentials = {
  serviceRegion: string
  speechKey: string
}

export const AWS_S3_REGION = 'eu-west-3'
export const AZURE_SPEECH_TRANSLATION_TARGET_LANGUAGE = 'fr'
export const DEFAULT_RECOGNITION_LANGUAGE = 'en-US'

export const AZURE_LANGUAGE_MAP: Record<string, string> = {
  // Most common languages
  'da-DK': 'Danois',
  'it-IT': 'Italien',
  'es-ES': 'Espagnol',
  'en-GB': 'Anglais (Royaume-Uni)',
  'ja-JP': 'Japonais',
  'fr-FR': 'Français',

  // European languages
  'de-DE': 'Allemand',
  'pt-PT': 'Portugais',
  'nl-NL': 'Néerlandais',
  'en-US': 'Anglais (États-Unis)',
  'sv-SE': 'Suédois',
  'fi-FI': 'Finnois',
  'no-NO': 'Norvégien',

  // Other world languages
  'zh-CN': 'Chinois (Mandarin)',
  'ru-RU': 'Russe',
  'ar-AE': 'Arabe (EAU)',
  'tr-TR': 'Turc',
  'he-IL': 'Hébreu',
  'ko-KR': 'Coréen',
  'hi-IN': 'Hindi',
  'pt-BR': 'Portugais (Brésil)',

  // Regional variants and less common languages
  'af-ZA': 'Afrikaans',
  'ar-DZ': 'Arabe (Algérie)',
  'eu-ES': 'Basque',
  'hr-HR': 'Croate',
  'is-IS': 'Islandais',
  'ga-IE': 'Irlandais',
  'it-CH': 'Italien (Suisse)',
  'fr-BE': 'Français (Belgique)',
  'fr-CA': 'Français (Canada)',
  'fr-CH': 'Français (Suisse)',
  'nl-BE': 'Néerlandais (Belgique)',
}

export const LANGUAGE_OPTIONS: LanguageOption[] = Object.entries(
  AZURE_LANGUAGE_MAP,
)
  .map(([code, name]) => ({ code, name }))
  .sort((a, b) => a.name.localeCompare(b.name))

export const AZURE_CANDIDATE_LANGUAGE_CODES = Object.keys(AZURE_LANGUAGE_MAP)
