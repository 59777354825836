import { addOggExtension } from 'common/soundKey'
import { useEffect, useState } from 'react'
import { OSO_SOUNDS } from 'shared/types/azureSpeech'
import { labelingConfigMap } from './Labeling'
import { Player } from './Player'
import { Button } from './components'
import { DropdownCheckboxes } from './components/DropdownCheckboxes'
import { TranslationSection } from './components/TranslationSection'
import { app } from './firebase'
import { getFilePathTime } from './utils'

interface Props {
  getUrl: (path: string) => Promise<string>
  enableLabeling: () => void
}

export const Listener = ({ getUrl, enableLabeling }: Props) => {
  const { playbackRates } = labelingConfigMap[import.meta.env.VITE_ONTOLOGY]

  const path =
    window.location.href.split('=')[1] ??
    window.location.pathname.replace(/^\//, '')

  const [soundUrl, setSoundUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    async function fetchUrl() {
      const soundUrl = await getUrl(addOggExtension(path))
      setSoundUrl(soundUrl)
    }

    fetchUrl()
  }, [path, getUrl])

  const labelTime = getFilePathTime(path)

  return (
    <div className="flex w-screen flex-col">
      {soundUrl && (
        <Player
          labelTime={labelTime}
          src={soundUrl}
          autoplay={true}
          mutedRanges={[]}
          playbackRates={playbackRates}
        ></Player>
      )}

      <TranslationSection
        particleRef={path}
        bucket={OSO_SOUNDS}
        Button={Button}
        DropdownCheckboxes={DropdownCheckboxes}
        app={app}
        compact={true}
      />

      <div className={`mt-4 flex flex-row justify-center`}>
        <Button primary onClick={enableLabeling}>
          Labelliser ce son
        </Button>
      </div>
    </div>
  )
}
