import { FirebaseKey, UserId } from './utils'

export const REPORT_KEY_FLAG = 'REPORT_KEY_FLAG'

export type Flag = {
  type: 'REPORT_KEY_FLAG'
  createdAt: number
  userId: UserId
}

export interface QueueItem {
  labelerUids?: string[]
  priority: number
  sortIndex: number
  labelersTarget: number
  flag?: Flag
}

export type QueueEntry<T extends QueueItem> = {
  key: FirebaseKey
  value: T
}
