import { useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import SpectrogramPlugin from 'wavesurfer.js/dist/plugins/spectrogram'
import colormapData from './hot-colormap.json'

const MAX_FREQUENCY = 8000
const SHOW_PLAYER = false

function convertXtoYLogarithmic(x: number, top: number, bottom: number) {
  const c = 1
  const d = MAX_FREQUENCY + 1
  const xNorm = x / (bottom - top)
  const yLog = Math.log10(c + xNorm * (d - c))
  const y = Math.pow(10, yLog) - 1

  return Math.trunc(y)
}

// This component only manage spectrogram and use the audio ref of the parent's player
const Waveform = ({
  audioElementRef,
  audioDuration,
}: {
  audioElementRef: React.RefObject<HTMLAudioElement>
  audioDuration: number
}) => {
  const waveformRef = useRef<HTMLDivElement>(null)
  const spectrogramRef = useRef<HTMLDivElement>(null)
  const wavesurferRef = useRef<WaveSurfer | null>(null)

  const [hoverPosition, setHoverPosition] = useState<{
    x: number
    y: number
    hz: number
  } | null>(null)

  useEffect(() => {
    const spectrogramRefCurrent = spectrogramRef.current
    if (
      audioElementRef.current &&
      waveformRef.current &&
      spectrogramRefCurrent
    ) {
      const spectrogram = SpectrogramPlugin.create({
        container: spectrogramRefCurrent,
        labels: true,
        colorMap: colormapData,
        frequencyMax: MAX_FREQUENCY,
        scale: 'linear',
      })

      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        height: SHOW_PLAYER ? 150 : 0,
        sampleRate: MAX_FREQUENCY * 2,
        plugins: [spectrogram],
        media: audioElementRef.current,
      })

      // Handle mouse move on spectrogram
      const handleSpectrogramMouseMove = (e: MouseEvent) => {
        if (spectrogramRefCurrent) {
          const { top, bottom, left, height } =
            spectrogramRefCurrent.getBoundingClientRect()

          const yInverted = bottom - e.clientY

          setHoverPosition({
            x: e.clientX - left - 60,
            y: e.clientY - height,
            hz: convertXtoYLogarithmic(yInverted, top, bottom),
          })
        }
      }

      const handleSpectrogramClick = (e: MouseEvent) => {
        if (spectrogramRefCurrent) {
          const { left, width } = spectrogramRefCurrent.getBoundingClientRect()
          const xPos = e.clientX - left

          const timePoint = (xPos / width) * audioDuration

          wavesurferRef.current?.setTime(timePoint)
        }
      }

      const handleSpectrogramMouseLeave = () => setHoverPosition(null)

      spectrogramRefCurrent.addEventListener(
        'mousemove',
        handleSpectrogramMouseMove,
      )

      spectrogramRefCurrent.addEventListener(
        'mouseleave',
        handleSpectrogramMouseLeave,
      )

      spectrogramRefCurrent.addEventListener('click', handleSpectrogramClick)

      return () => {
        wavesurferRef.current?.destroy()
        spectrogramRefCurrent.removeEventListener(
          'mousemove',
          handleSpectrogramMouseMove,
        )
        spectrogramRefCurrent.removeEventListener(
          'mouseleave',
          handleSpectrogramMouseLeave,
        )
        spectrogramRefCurrent.removeEventListener(
          'click',
          handleSpectrogramClick,
        )
      }
    }
    return () => {}
  }, [audioElementRef, audioDuration])

  return (
    <div className="flex flex-row items-center gap-4">
      {/* insert play Button here if we shall use the audioFile directly */}
      <div className="flex w-full flex-col">
        <div ref={waveformRef} />
        <div ref={spectrogramRef} className="cursor-[crosshair]" />
        {hoverPosition && (
          <div
            className="absolute z-20 whitespace-nowrap rounded bg-black p-1 text-white"
            style={{
              left: `${hoverPosition.x}px`,
              top: `${hoverPosition.y}px`,
              transform: 'translate(-50%, -100%)',
              pointerEvents: 'none',
            }}
          >
            {hoverPosition.hz} Hz
          </div>
        )}
      </div>
    </div>
  )
}

export default Waveform
