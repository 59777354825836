import type { FirebaseApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useCallback, useState } from 'react'
import { DEFAULT_REGION } from '../firebase/region'
import {
  BucketName,
  TranslateSpeechParams,
  TranslateSpeechResponseData,
} from '../types/azureSpeech'
import { onError } from '../utils/web/error'

export const useTranslateParticle = (app: FirebaseApp) => {
  const functions = getFunctions(app, DEFAULT_REGION)
  const transcribeAndTranslateParticle = httpsCallable<
    Omit<TranslateSpeechParams, 'awsCredentials' | 'azureCredentials'>,
    TranslateSpeechResponseData
  >(functions, 'transcribeAndTranslateParticle')

  const [speechResult, setSpeechResult] =
    useState<TranslateSpeechResponseData | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const translateParticle = useCallback(
    async (
      particleRef: string,
      bucket: BucketName,
      candidateLanguages: string[],
    ) => {
      setLoading(true)
      setSpeechResult(null)

      try {
        const result = await transcribeAndTranslateParticle({
          particleRef,
          bucket,
          candidateLanguages,
        })
        setSpeechResult(result.data)
      } catch (error) {
        onError(error)
      } finally {
        setLoading(false)
      }
    },
    [transcribeAndTranslateParticle],
  )

  return {
    speechResult,
    translateParticle,
    loading,
  }
}
