import { useEffect, useRef, useState } from 'react'
import { plural } from 'shared/utils/plural'
import { Button } from './Button'
import { Input } from './Input'

export type DropdownProps = {
  children: React.ReactNode
  options: string[]
  value: string[]
  onValidation: (value: string[]) => void
  resetValue: string[]
  disabled: boolean
  maxSelections?: number
  buttonClassName?: string
}

function normalizeText(text: string) {
  return text
    .trim()
    .replace(/-/g, '_')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .replace(/[^\w\s']/g, '') // Remove punctuation
    .replace(/ /g, '_')
    .toLowerCase()
}

export const DropdownCheckboxes: React.FC<DropdownProps> = ({
  children,
  options,
  value,
  onValidation,
  resetValue,
  disabled,
  maxSelections,
  buttonClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<string[]>(value)
  const [searchedText, setSearchedText] = useState<string>('')
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleOptionClick = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((val) => val !== option))
    } else {
      if (!maxSelections || selectedOptions.length < maxSelections) {
        setSelectedOptions([...selectedOptions, option])
      }
    }
  }

  const handleResetClick = () => {
    setSelectedOptions(resetValue)
    onValidation(resetValue)
    setIsOpen(false)
  }

  const handleValidateClick = () => {
    onValidation(selectedOptions)
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      ref={dropdownRef}
      className={`cursor-pointer rounded bg-sky-700 focus:outline-none disabled:opacity-50`}
    >
      <Button
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
        className={buttonClassName || ''}
      >
        {children}
      </Button>
      {isOpen && (
        <div className="absolute z-20 flex max-h-[50rem] flex-col overflow-y-auto rounded bg-gray-900">
          <div className="sticky top-0 flex flex-col gap-2 bg-gray-900 p-2">
            <div className="flex items-center justify-between gap-2">
              <p className="flex flex-1">
                {plural(selectedOptions, ['item', 'sélectionné'], true)}
              </p>
              <Button onClick={handleResetClick}>Reset</Button>
              <Button onClick={handleValidateClick}>Valider</Button>
            </div>
            <Input
              type="text"
              value={searchedText}
              onChange={(e) => setSearchedText(normalizeText(e.target.value))}
              placeholder="Chercher"
            />
          </div>
          <div className="p-1">
            {options
              .filter((option) => normalizeText(option).includes(searchedText))
              .map((option) => (
                <div className="flex gap-1" key={option}>
                  <input
                    type="checkbox"
                    name={option}
                    value={option}
                    checked={selectedOptions.includes(option)}
                    onChange={() => !disabled && handleOptionClick(option)}
                    disabled={Boolean(
                      disabled ||
                        (!selectedOptions.includes(option) &&
                          maxSelections &&
                          selectedOptions.length >= maxSelections),
                    )}
                  />
                  <label htmlFor={option}>{option}</label>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}
