import { Nullable } from '../hooks/createUseMergedFirebase'
import { DatabaseSubType, InvalidPath } from './databaseSubType'

// https://github.com/microsoft/TypeScript/issues/27024#issuecomment-421529650
type Equals<X, Y> =
  (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2
    ? true
    : false

type Or<T1, T2> = T1 extends true ? true : T2 extends true ? true : false

// In case the database type Y is a partial or an optional,
// also compare X with NotUndefined<Y>
type AlmostEquals<X, Y> = Or<Equals<X, Y>, Equals<X, NotUndefined<Y>>>

type ContainsInvalidPath<T> = T extends `${InvalidPath}${infer _}`
  ? true
  : never

// Square brackets used to prevent distributive conditionnal
// https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
export type IsPathValid<T> = [T] extends [never]
  ? 'Never DataType'
  : [ContainsInvalidPath<T>] extends [never]
    ? true
    : 'Has invalid path'

// Inspired by the ThenableReference type definition from firebase
type Thenable<T> = T & Pick<Promise<T>, 'then' | 'catch'>

// Common parts from the Reference (Admin SDK) and DatabaseReference (Web SDK) types
interface Reference {
  readonly key: string | null
  readonly parent: Reference | null
}

interface PushReference extends Reference {
  key: string // key cannot be null after a push
}

export type ThenableReference = Thenable<Reference>
export type ThenablePushReference = Thenable<PushReference>

export function refFullPath(ref: Reference): string {
  if (ref.key === null || ref.parent === null) return '' // root
  return `${refFullPath(ref.parent)}/${ref.key}`
}

export type IgnoreDBTyping = never

// A type where Base properties that are also in Extra are forbidden
type RemoveExtra<Base, Extra> = Record<Exclude<keyof Extra, keyof Base>, never>
type IsSubType<Sub, Base> = Sub extends Partial<Base> & RemoveExtra<Base, Sub>
  ? true
  : false

export function update<DatabaseSchema>(
  update: (path: string, value: object) => Promise<void>,
) {
  return async function <
    Path extends string,
    ValueType extends object,
    DatabaseType = DatabaseSubType<DatabaseSchema, Path>,
  >(
    path: Path,
    value: DatabaseType extends object
      ? IsSubType<ValueType, Nullable<NotUndefined<DatabaseType>>> extends true
        ? ValueType
        : never
      : never,
  ) {
    return update(path, value)
  }
}

// For a union type X | undefined, the test is applied on
// each type, as a map, resulting in X | never = X
export type NotUndefined<T> = T extends undefined ? never : T

export function set<DatabaseSchema>(
  set: (path: string, value: unknown) => Promise<void>,
) {
  return async function <
    Path extends string,
    ValueType,
    DatabaseType = DatabaseSubType<DatabaseSchema, Path>,
  >(
    path: Path,
    value: AlmostEquals<ValueType, DatabaseType> extends true
      ? ValueType
      : never,
  ) {
    return set(path, value)
  }
}

export function push<DatabaseSchema>(
  push: (path: string, value: unknown) => ThenableReference,
) {
  return function <
    Path extends string,
    ValueType,
    DatabaseType = DatabaseSubType<DatabaseSchema, Path>,
  >(
    path: Path,
    value: AlmostEquals<Record<string, ValueType>, DatabaseType> extends true
      ? ValueType
      : AlmostEquals<
            Partial<Record<string, ValueType>>,
            DatabaseType
          > extends true
        ? ValueType
        : never,
  ) {
    return push(path, value) as ThenablePushReference
  }
}

export function getPushKey<DatabaseSchema>(
  push: (path: string) => ThenableReference,
) {
  return function <
    Path extends string,
    DatabaseType = DatabaseSubType<DatabaseSchema, Path>,
  >(path: DatabaseType extends Record<string, unknown> ? Path : never) {
    return (push(path) as ThenablePushReference).key
  }
}

type PathWithoutLastPart<Path extends string> = PathWithoutLastPartHelper<
  Path,
  ''
>

type PathWithoutLastPartHelper<
  Path extends string,
  Current extends string,
> = Path extends `${infer Head}/${infer Tail}`
  ? Current extends ''
    ? PathWithoutLastPartHelper<Tail, `${Head}`>
    : PathWithoutLastPartHelper<Tail, `${Current}/${Head}`>
  : Path extends `${infer _Head}`
    ? Current
    : Path extends string
      ? Current
      : `Non string path '${Path}'`

type PathLastPart<Path extends string> =
  Path extends `${infer _Head}/${infer Tail}`
    ? PathLastPart<Tail>
    : Path extends `${infer Head}`
      ? Head
      : Path extends string
        ? Path
        : `Non string path '${Path}'`

export function remove<DatabaseSchema>(
  remove: (path: string) => Promise<void>,
) {
  return async function <
    Path extends string,
    DatabaseType = DatabaseSubType<DatabaseSchema, Path>,
  >(
    path: IsPathValid<DatabaseType> extends true
      ? undefined extends DatabaseType
        ? Path // success when subtype can be undefined...
        : PathLastPart<Path> extends keyof NotUndefined<
              DatabaseSubType<DatabaseSchema, PathWithoutLastPart<Path>>
            >
          ? Path // ...or if removing a key in a Record or Object
          : 'Cannot be removed'
      : never,
  ) {
    return remove(path)
  }
}

type DatasnapshotMock = { val: () => unknown }

export function convertNullToUndefined<T>(value: T | null) {
  return value === null ? undefined : value
}

export function get<DatabaseSchema>(
  get: (path: string) => Promise<DatasnapshotMock>,
) {
  return async function <
    Path extends string,
    DatabaseType = DatabaseSubType<DatabaseSchema, Path>,
  >(path: IsPathValid<DatabaseType> extends true ? Path : never) {
    return get(path).then(
      (snapshot) => convertNullToUndefined(snapshot.val()) as DatabaseType,
    )
  }
}
